import React, { useEffect, useState } from 'react';
import './Whatsapp.css';
import { FaChevronDown, FaWhatsapp } from 'react-icons/fa';

function Whatsapp() {
    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        // Check if the popup has already been shown (stored in localStorage)
        const hasPopupBeenShown = localStorage.getItem('popupShown');

        // If it's not in localStorage (i.e., first visit), show the popup after 10 seconds
        if (!hasPopupBeenShown) {
            const timer = setTimeout(() => {
                togglePopup();
                // Mark the popup as shown in localStorage
                localStorage.setItem('popupShown', 'true');
            }, 10000); // 10000 milliseconds = 10 seconds

            // Clear the timer if the component unmounts
            return () => clearTimeout(timer);
        }
    }, []); // Empty dependency array ensures this runs only once after initial render

    return (
        <div className="whatsapp-container">
            <div onClick={togglePopup} className="float-whatsapp">
                <button type="button" className="whatsapp-button">
                    {isOpen ? 
                        <FaChevronDown color="#fff" size={20} /> 
                        : 
                        <FaWhatsapp color="#fff" size={20} />
                    }
                </button>
            </div>

            {isOpen && (
                <div className={`whatsapp-popup ${isOpen ? 'open' : ''}`}>
                    <div className="popup-message">
                        <b className='text-dark'>Hi there!</b>
                        <p>If you need any assistance or have questions, feel free to message us. We're happy to help!</p>
                        <a href="https://wa.me/918147013708?text=Hello,I need help with something." target="_blank" rel="noopener noreferrer">
                            <button type="button" className="chat-button w-100 align-center justify-content-center">
                                <FaWhatsapp color="#fff" size={20} className='me-2' />
                                Chat With Us
                            </button>
                        </a>
                        <div className="powered-by">
                            <small>Powered by w3builders.in</small>
                        </div>
                        <div className="arrow-box"></div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Whatsapp;
