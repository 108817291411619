import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import './ShopByGender.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import womenplaintshirts from '../../assets/images/gender/womenplaintshirts.png'
import womensweatshirts from '../../assets/images/gender/womensweatshirts.png'
import womenprintedtees from '../../assets/images/gender/womenprintedtees.png'
import womenmore from '../../assets/images/gender/womenmore.png'
function ShopForHer() {

    return (
        <section className='pb-2 wow fadeInDown pt-0'>
            <Container>
                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                        <h4 className='title text-black py-2 text-uppercase'>For <span className='gradient-text'>Her</span></h4>
                    </div>
                </div>
                <Row className='g-0'>
                    <Col sm={6} xs={6} lg={3} md={6} className='mb-1'>
                        <Link to="/gender/For Her/Plain%20T-shirts">
                            <Card className='me-1 bg-transparent card-hover h-100 border-0'>
                                <div className="card-img-container  h-100" >
                                    <Card.Img src={womenplaintshirts} alt="Gender Image" variant='top' className='h-100' />
                                </div>
                            </Card>
                        </Link>
                    </Col>
                    <Col sm={6} xs={6} lg={3} md={6} className='mb-1'>
                        <Link to="/gender/For Her/Sweatshirts">
                            <Card className='me-1 bg-transparent card-hover h-100 border-0'>
                                <div className="card-img-container  h-100" >
                                    <Card.Img src={womensweatshirts} alt="Gender Image" variant='top' className='h-100' />
                                </div>
                            </Card>
                        </Link>
                    </Col>
                    <Col sm={12} xs={6} lg={3} md={6} className='mb-1'>
                        <Link to="/gender/For Her/Printed%20T-shirts">
                            <Card className='me-1 bg-transparent card-hover h-100 border-0'>
                                <div className="card-img-container  h-100" >
                                    <Card.Img src={womenprintedtees} alt="Gender Image" variant='top' className='h-100' />
                                </div>
                            </Card>
                        </Link>
                    </Col>
                    <Col sm={12} xs={6} lg={3} md={6} className='mb-1'>
                        <Link to="/collection/For Her">
                            <Card className='me-1 bg-transparent card-hover h-100 border-0'>
                                <div className="card-img-container  h-100" >
                                    <Card.Img src={womenmore} alt="Gender Image" variant='top' className='h-100' />
                                </div>
                            </Card>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default ShopForHer;
