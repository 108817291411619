import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Policies.css'; // Custom CSS for the font style

const CommunityGuidelines = () => {
  return (
    <Container className="community-guidelines sectionView">
      <Row>
        <Col>
          <h1 className="text-center gradient-text">Community Guidelines</h1>
          <p className="text-center"><em>Last updated: 1st September 2024</em></p>
          
          <h2>1. Introduction</h2>
          <p>Welcome to the Style Spotlight Co. community. Our community guidelines aim to create a positive and respectful environment for all users. By participating in our community, you agree to abide by these guidelines.</p>
          
          <h2>2. Respectful Behavior</h2>
          <h3>a. Respect Others</h3>
          <p>Treat all community members with respect and courtesy.</p>
          
          <h3>b. No Harassment</h3>
          <p>Harassment, hate speech, and discriminatory behavior will not be tolerated.</p>
          
          <h2>3. Content Guidelines</h2>
          <h3>a. Appropriate Content</h3>
          <p>Share content that is relevant and appropriate for the community.</p>
          
          <h3>b. No Spam</h3>
          <p>Avoid spamming the community with irrelevant or promotional content.</p>
          
          <h2>4. Consequences of Violations</h2>
          <p>Violations of our community guidelines may result in warnings, suspension, or removal from the community, at our discretion.</p>
          
          <h2>5. Contact Us</h2>
          <p>If you have any questions or concerns about our Community Guidelines, please contact us from the contact us page.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default CommunityGuidelines;
