import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import './Sliders.css';
import { Link } from 'react-router-dom';

const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    arrows: false,
    centerMode: false,
};
function Sliders() {

    const [sliders, setSliders] = useState([]);
    const baseURL = 'https://stylespotlightco.in/';
    useEffect(() => {
        getSliders();
    }, [])

    const getSliders = async () => {
        try {
            const response = await axios.get(`${baseURL}/api/client_api/Sliders/index.php`);
            setSliders(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    // const = (name) => {
    //     return name.replace(/ /g, '-').replace(/[^\w-]+/g, '');
    // };

    return (
        <div>
            <section>
                <div className="sliderDesktop sectionView">
                    <Container fluid className="p-0">
                        <Slider {...sliderSettings}>
                            {sliders && sliders.map((item, index) => (
                                <Link
                                    to={`${item.slider_type === 'product'
                                        ? `/${item.slider_type}/${item.additional_data.product}`
                                        : (item.slider_type == 'promotion' ? '/' : `/${item.slider_type}/${item.additional_data.collection}`)
                                        }`}
                                    key={index} className="slide-item">
                                    <img
                                        src={baseURL + item.image_path}
                                        alt="Slide 1"
                                        className='sliderImages'
                                    />
                                </Link>
                            ))}
                        </Slider>
                    </Container>
                </div>
                <div className="sliderMobile sectionView">
                    <Slider {...sliderSettings}>
                        {sliders && sliders.map((item, index) => (
                            <Link
                                to={`${item.slider_type === 'product'
                                    ? `/${item.slider_type}/${item.additional_data.product}`
                                    : (item.slider_type == 'promotion' ? '/' : `/${item.slider_type}/${item.additional_data.collection}`)
                                    }`}
                                key={index} className="slide-item">
                                <img
                                    src={baseURL + item.mobile_image_path}
                                    alt="Slide 1"
                                    className='sliderImages'
                                />
                            </Link>
                        ))}
                    </Slider>
                </div>
            </section>
        </div>
    )
}

export default Sliders
