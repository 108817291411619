import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BsTruck } from "react-icons/bs";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { GoShieldCheck } from "react-icons/go";
import { IoStorefrontOutline } from "react-icons/io5";
import { FaCottonBureau } from 'react-icons/fa';

const Features = () => {
    return (
        <div className="bg-light my-5">
            <Container className="py-2">
                <Row className="text-center align-items-center justify-content-center ">
                    <Col md={6} sm={12} lg={3} className='d-flex flex-column flex-md-column align-items-center justify-content-center my-4'>
                        <FaCottonBureau size={50} className='mb-2 text-muted' />
                        <div className='text-center text-md-center mx-2 mt-1'>
                            <h6 className="my-0 text-uppercase gradient-text">100% Cotton products</h6>
                            <p className='mb-0 text-dark' style={{ fontSize: '0.8rem' }}>Our 98% clothes are made up of 100% cotton.</p>
                        </div>
                    </Col>
                    <Col md={6} sm={12} lg={3} className='d-flex flex-column flex-md-column align-items-center justify-content-center my-4'>
                        <BsTruck size={50} className='mb-2 text-muted' />
                        <div className='text-center text-md-center mx-2 mt-1'>
                            <h6 className="my-0 text-uppercase gradient-text">Shipping across India</h6>
                            <p className='mb-0 text-dark' style={{ fontSize: '0.8rem' }}>We deliver all over India for free!</p>
                        </div>
                    </Col>
                    <Col md={6} sm={12} lg={3} className='d-flex flex-column flex-md-column align-items-center justify-content-center my-4'>
                        <GoShieldCheck size={50} className='mb-2 text-muted' />
                        <div className='text-center text-md-center mx-2 mt-1'>
                            <h6 className="my-0 text-uppercase gradient-text">SECURE PAYMENT</h6>
                            <p className='mb-0 text-dark' style={{ fontSize: '0.8rem' }}>Secure payments through Razorpay Payment Gateway</p>
                        </div>
                    </Col>
                    <Col md={6} sm={12} lg={3} className='d-flex flex-column flex-md-column align-items-center justify-content-center my-4'>
                        <TfiHeadphoneAlt size={50} className='mb-2 text-muted' />
                        <div className='text-center text-md-center mx-2 mt-1'>
                            <h6 className="my-0 text-uppercase gradient-text">Customer SUPPORT</h6>
                            <p className='mb-0 text-dark' style={{ fontSize: '0.8rem' }}>Call us for any enquiry</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Features;
