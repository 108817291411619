import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { IoArrowForward, IoChevronBack, IoChevronForward } from 'react-icons/io5';
import './ShopByCollections.css'
import Slider from 'react-slick';
import axios from 'axios';
import { Link } from 'react-router-dom';
function ShopByCollections() {
    const [collections, setCollections] = useState([]);
    const [loading, setLoading] = useState(true);
    const baseURL = 'https://stylespotlightco.in/'

    const [activeSlide, setActiveSlide] = useState(0);

    let sliderRef = useRef(null);

    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    const productsSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        swipe: true,
        slidesToShow: 5.4,
        slidesToScroll: 5,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: false,
        arrows: false,
        beforeChange: (current, next) => {
            setActiveSlide(next);
        },
        responsive: [
            {
                breakpoint: 1400, // xl (extra large)
                settings: {
                    slidesToShow: 5.4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 1200, // lg (large)
                settings: {
                    slidesToShow: 5.2,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 992, // md (medium)
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 768, // sm (small)
                settings: {
                    slidesToShow: 4.15,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 576, // xs (extra small)
                settings: {
                    slidesToShow: 2.35,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 486, // xxs (extra extra small)
                settings: {
                    slidesToShow: 2.35,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    useEffect(() => {
        getCollections();
    }, [])

    const getCollections = async () => {
        try {
            const response = await axios.get('https://stylespotlightco.in/api/client_api/Gender/index.php');
            const sortedCollections = response.data.sort((a, b) => a.name.localeCompare(b.name));
            setCollections(sortedCollections);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const GenderCard = ({ item }) => (
        <Link to={`/collection/${item.name}`}>
            <Card className='me-1 bg-transparent card-hover gendersCard border-0'>
                <div className="card-img-container">
                    <Card.Img src={baseURL + item.image_path} alt="Gender Image" style={{ borderRadius: '10px' }} />
                    <div className="card-overlay"></div>
                </div>
                <div className="w-100 px-2 py-2 d-flex justify-content-center align-items-center" style={{ position: 'absolute', bottom: 0 }}>
                    <div>
                        <Card.Title className='text-start gradient-text mb-0 rounded-0 fw-bold ShopByGenderTag'>
                            {item.name.split(' ').map((word, index) => (
                                <span key={index} className='gradient-text'>
                                    {word}
                                    {index < item.name.split(' ').length - 1 && ' '}
                                </span>
                            ))}
                        </Card.Title>
                    </div>
                </div>
            </Card>
        </Link>
    );



    return (
        <div>
            <Container>
                <section className='py-3'>
                    <Row className="g-0">
                        <Col>
                        <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <h4 className='title text-black py-2 text-uppercase'>Shop By <span className='gradient-text'>Collections</span></h4>
                                </div>
                                <div>
                                    <button
                                        className="btn me-2  swiper-arrows"
                                        style={{
                                            border: `2px solid ${activeSlide === 0 ? '#333' : '#fff'}`,
                                        }}
                                        onClick={previous}
                                    >
                                        <IoChevronBack color={activeSlide === 0 ? '#333' : '#fff'} size={25} />
                                    </button>
                                    <button
                                        style={{
                                            border: '2px solid #fff',
                                        }}
                                        className="btn swiper-arrows"
                                        onClick={next}
                                    >
                                        <IoChevronForward color='#fff' size={25} />
                                    </button>
                                </div>
                            </div>
                            {loading ? (
                                <div>Loading...</div>
                            ) : (
                                <Slider
                                    ref={slider => {
                                        sliderRef = slider;
                                    }}
                                    {...productsSettings}
                                    className="top-categories pt-2"
                                >
                                    {collections.map((item) => (
                                        <GenderCard key={item.id} item={item} />
                                    ))}
                                </Slider>
                            )}
                        </Col>
                    </Row>
                </section>
            </Container>
        </div>
    );
}

export default ShopByCollections;
