import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Policies.css'; // Custom CSS for the font style

const Disclaimer = () => {
  return (
    <Container className="disclaimer sectionView">
      <Row>
        <Col>
          <h1 className="text-center gradient-text">Disclaimer</h1>
          <p className="text-center"><em>Last updated: 1st September 2024</em></p>
          
          <h2>1. Introduction</h2>
          <p>All information provided on Style Spotlight Co. ("we," "us," "our") is for informational purposes only. We strive to ensure the accuracy and completeness of the content, but we do not guarantee its reliability or suitability for any particular purpose.</p>
          
          <h2>2. Use of Information</h2>
          <p>Any reliance you place on the information provided on this website is at your own risk. We are not liable for any loss or damage arising from the use of this information.</p>
          
          <h2>3. Professional Advice</h2>
          <p>The content on our website is not a substitute for professional advice. You should seek the advice of qualified professionals for specific concerns or issues.</p>
          
          <h2>4. Changes to Information</h2>
          <p>We may update or change the information on our website without notice. It is your responsibility to verify the accuracy and relevance of the information.</p>
          
          <h2>5. Contact Us</h2>
          <p>If you have any questions or concerns about our Disclaimer, please contact us from the contact us page.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Disclaimer;
