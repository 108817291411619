

import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import SecureLS from "secure-ls";

export const WishlistContext = createContext();

export const WishlistProvider = ({ children }) => {
    const ls = new SecureLS({ encodingType: 'aes' });
    const [wishlistCount, setWishlistCount] = useState(0);
    const [wishlistItems, setWishlistItems] = useState([]);

    const fetchWishlistCount = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');

        if (username == "") {
            if (sessionId !== null) {
                getWishlistCount(sessionId, 'na');
            }
        } else {
            getWishlistCount('na', username);
        }
    };

    const fetchWishlistItems = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        if (username == "") {
            if (sessionId !== null) {
                getWishlistItems(sessionId, 'na');
            }
        } else {
            getWishlistItems('na', username);
        }
    };

    const getWishlistCount = (sessionId, username) => {
        axios.get(`https://stylespotlightco.in/api/client_api/wishlist/WishlistCount/?ipadd=${sessionId}&email=${username}`)
            .then(response => {
                if (response.data == null) {
                    setWishlistCount(0);
                } else {
                    const wishlistCount = parseInt(response.data, 10);
                    setWishlistCount(wishlistCount);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const getWishlistItems = (sessionId, username) => {
        axios.get(`https://stylespotlightco.in/api/client_api/wishlist/?ipadd=${sessionId}&email=${username}`)
            .then(response => {
                setWishlistItems(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const addToWishlist = async (sessionId, username, item) => {
        try {
            const response = await axios.get('https://stylespotlightco.in/api/client_api/wishlist/addtoWishlist/index.php', {
                params: {
                    prodcode: item.selectedProdCode,
                    ipadd: sessionId,
                    qty: 1,
                    email: username
                }
            });
            if (response.data.message === 'added to wishlist!') {
                fetchWishlistItems(); // Refresh wishlist after adding item
                setWishlistCount(prevCount => prevCount + 1); // Update wishlist count
                return true; // Indicate success
            } else if (response.data.message === 'Deleted From wishlist!') {
                fetchWishlistItems(); // Refresh wishlist after adding item
                setWishlistCount(prevCount => prevCount - 1); // Update wishlist count
                return true; // Indicate success
            } else {
                console.error(response.data.error);
                return false; // Indicate failure
            }
        } catch (error) {
            console.error('Error adding to wishlist:', error);
            return false; // Indicate failure
        }
    };


    const removeFromWishlist = async (sessionId, username, item) => {
        try {
            const response = await axios.get('https://stylespotlightco.in/api/client_api/wishlist/removefromWishlist/index.php', {
                params: {
                    prodcode: item.prod_code,
                    ipadd: sessionId,
                    qty: 1,
                    email: username
                }
            });
            console.log(response.data);
            if (response.data.message === 'Removed from wishlist') {
                fetchWishlistItems(); // Refresh wishlist after removing item
                setWishlistCount(wishlistCount - 1); // Update wishlist count
            } else {
                console.error(response.data.message);
            }
        } catch (error) {
            console.error('Error removing from wishlist:', error);
        }
    };

    useEffect(() => {
        fetchWishlistCount();
        fetchWishlistItems();
    }, []);

    return (
        <WishlistContext.Provider value={{ wishlistCount, wishlistItems, addToWishlist, removeFromWishlist }}>
            {children}
        </WishlistContext.Provider>
    );
};
