import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Badge, Button } from 'react-bootstrap';
import { IoAddCircleOutline, IoBagAddOutline, IoBagHandleOutline, IoStar, IoStarOutline } from 'react-icons/io5';
import Slider from 'react-slick';
import './Banner.css'
import { Link } from 'react-router-dom';
import banner from '../../assets/images/banner/freshdrops.png'
function Banner() {


    return (
        <Container>
            <a href='collection/New Arrivals'>
                <Card.Img src={banner} alt="promo Image" className='h-100 rounded-3 mb-2' />
            </a>
        </Container>
    )
}

export default Banner
