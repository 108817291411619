import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Badge, Button } from 'react-bootstrap';
import { IoAddCircleOutline, IoBagAddOutline, IoBagHandleOutline, IoStar, IoStarOutline } from 'react-icons/io5';
import Slider from 'react-slick';
import './Showcase.css'
import { Link } from 'react-router-dom';
import oversizedprinted from '../../assets/images/showcase/oversizedprinted.gif';
import acidwashoversized from '../../assets/images/showcase/acidwashoversized.gif';
import terrycollection from '../../assets/images/showcase/terrycollection.gif';
import acidwashhoodie from '../../assets/images/showcase/acidwashhoodie.gif';
import fullsleeves from '../../assets/images/showcase/fullsleeves.png';
import polotshirts from '../../assets/images/showcase/polo.png';
function Showcase() {

    return (
        <section className='pt-2 wow fadeInDown'>
            <Container>
                {/* Men Collections Only */}
                {/* <Col sm={12} lg={12} md={12} className='mb-1'>
                    <Row className='g-1'>
                        <Col sm={12} lg={12} md={12}>
                            <Row className='g-0'>
                                <Col sm={6} xs={6} lg={6} md={6}>
                                    <Link to="/collection/Plain%20T-shirts">
                                        <Card className='me-1 bg-transparent card-hover border-0'>
                                            <div className="card-img-container">
                                                <Card.Img src={fullsleeves} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} className='h-100' />
                                            </div>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col sm={6} xs={6} lg={6} md={6}>
                                    <Link to="/collection/Oversized%20Anime%20Printed%20T-shirts">
                                        <Card className='me-1 bg-transparent card-hover border-0'>
                                            <div className="card-img-container">
                                                <Card.Img src={oversizedprinted} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} className='h-100' />
                                            </div>
                                        </Card>
                                    </Link>
                                </Col >
                            </Row>
                        </Col >
                    </Row>
                </Col> */}
                <Row className='g-0'>
                    <Col sm={12} lg={6} md={12} className='mb-1'>
                        <Row className='g-1'>
                            <Col sm={12} lg={12} md={12}>
                                <Row className='g-0'>
                                    <Col sm={6} xs={6} lg={6} md={6}>
                                        <Link to="/collection/Oversized%20Men's%20T-shirts">
                                            <Card className='me-1 bg-transparent card-hover border-0'>
                                                <div className="card-img-container">
                                                    <Card.Img src={oversizedprinted} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} className='h-100' />
                                                </div>
                                            </Card>
                                        </Link>
                                    </Col>
                                    <Col sm={6} xs={6} lg={6} md={6}>
                                        <Link to="/product/for-him-full-sleeve-t-shirt---180-gsm-maroon">
                                            <Card className='me-1 bg-transparent card-hover border-0'>
                                                <div className="card-img-container">
                                                    <Card.Img src={fullsleeves} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} className='h-100' />
                                                </div>
                                            </Card>
                                        </Link>
                                    </Col >
                                </Row>
                            </Col >
                            <Col sm={12} lg={12} md={12}>
                                <Link to="/collection/Polo%20T-shirts">
                                    <Card className='me-1 bg-transparent card-hover h-100 border-0'>
                                        <div className="card-img-container  h-100" >
                                            <Card.Img src={polotshirts} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} className='h-100' />
                                        </div>
                                    </Card>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} lg={6} md={12} className='mb-1'>
                        <Row className='g-1'>
                            <Col sm={12} lg={12} md={12}>
                                <Link to="/product/new-unisex-acid-wash-oversized-t-shirts---ssc-olive-green-240-gsm">
                                    <Card className='me-1 bg-transparent card-hover border-0'>
                                        <div className="card-img-container">
                                            <Card.Img src={acidwashoversized} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} className='h-100' />
                                        </div>
                                    </Card>
                                </Link>
                            </Col>
                            <Col sm={12} lg={12} md={12}>
                                <Row className='g-0'>
                                    <Col sm={6} xs={6} lg={6} md={6}>
                                        <Link to="/product/terry-oversized-t-shirt---ssc-280gsm-navy-blue">
                                            <Card className='me-1 bg-transparent card-hover border-0'>
                                                <div className="card-img-container">
                                                    <Card.Img src={terrycollection} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} className='h-100' />
                                                </div>
                                            </Card>
                                        </Link>
                                    </Col>
                                    <Col sm={6} xs={6} lg={6} md={6}>
                                        <Link to="/product/acid-wash-hoodies---ssc-300gsm-maroon">
                                            <Card className='me-1 bg-transparent card-hover border-0'>
                                                <div className="card-img-container">
                                                    <Card.Img src={acidwashhoodie} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} className='h-100' />
                                                </div>
                                            </Card>
                                        </Link>
                                    </Col >
                                </Row>
                            </Col >
                        </Row>
                    </Col>
                </Row>
                {/* Women COllection Only */}
                {/* <Row className='g-0'>
                    <Col sm={12} lg={6} md={12} className='mb-1'>
                        <Row className='g-1'>
                            <Col sm={12} lg={12} md={12}>
                                <Link to="/collection/Oversized%20Solid%20T-shirts">
                                    <Card className='me-1 bg-transparent card-hover border-0'>
                                        <div className="card-img-container">
                                            <Card.Img src={banner2} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} className='h-100' />
                                        </div>
                                    </Card>
                                </Link>
                            </Col>
                            <Col sm={12} lg={12} md={12}>
                                <Row className='g-1'>
                                    <Col sm={6} lg={6} md={6}>
                                        <Link to="/collection/Oversized%20Solid%20T-shirts">
                                            <Card className='me-1 bg-transparent card-hover border-0'>
                                                <div className="card-img-container">
                                                    <Card.Img src={banner2} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} className='h-100' />
                                                </div>
                                            </Card>
                                        </Link>
                                    </Col>
                                    <Col sm={6} lg={6} md={6}>
                                        <Link to="/collection/Plain%20T-shirts">
                                            <Card className='me-1 bg-transparent card-hover border-0'>
                                                <div className="card-img-container">
                                                    <Card.Img src={banner3} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} className='h-100' />
                                                </div>
                                            </Card>
                                        </Link>
                                    </Col >
                                </Row>
                            </Col >
                        </Row>
                    </Col>
                    <Col sm={12} lg={6} md={12} className='mb-1'>
                        <Link to="/collection/Oversized%20Anime%20Printed%20T-shirts">
                            <Card className='me-1 bg-transparent card-hover h-100 border-0'>
                                <div className="card-img-container  h-100" >
                                    <Card.Img src={banner1} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} className='h-100' />
                                </div>
                            </Card>
                        </Link>
                    </Col>
                </Row> */}
            </Container>
        </section>
    )
}

export default Showcase
