import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import './ShopByGender.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import male from '../../assets/images/gender/male.png'
import female from '../../assets/images/gender/female.png'
import unisex from '../../assets/images/gender/unisex.png'
import kids from '../../assets/images/gender/kids.png'
function ShopByGender() {

    return (
        <section className='pb-2 wow fadeInDown pt-0'>
            <Container>
                <Row className='g-0'>
                    <Col sm={6} xs={6} lg={3} md={6} className='mb-1'>
                        <Link to="/collection/For Him">
                            <Card className='me-1 bg-transparent card-hover h-100 border-0'>
                                <div className="card-img-container  h-100" >
                                    <Card.Img src={male} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} className='h-100' />
                                </div>
                            </Card>
                        </Link>
                    </Col>
                    <Col sm={6}  xs={6} lg={3} md={6} className='mb-1'>
                        <Link to="/collection/For Her">
                            <Card className='me-1 bg-transparent card-hover h-100 border-0'>
                                <div className="card-img-container  h-100" >
                                    <Card.Img src={female} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} className='h-100' />
                                </div>
                            </Card>
                        </Link>
                    </Col>
                    <Col sm={6}  xs={6} lg={3} md={6} className='mb-1'>
                        <Link to="/collection/kids">
                            <Card className='me-1 bg-transparent card-hover h-100 border-0'>
                                <div className="card-img-container  h-100" >
                                    <Card.Img src={kids} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} className='h-100' />
                                </div>
                            </Card>
                        </Link>
                    </Col>
                    <Col sm={6}  xs={6} lg={3} md={6} className='mb-1'>
                        <Link to="/collection/Unisex">
                            <Card className='me-1 bg-transparent card-hover h-100 border-0'>
                                <div className="card-img-container  h-100" >
                                    <Card.Img src={unisex} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} className='h-100' />
                                </div>
                            </Card>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default ShopByGender;
