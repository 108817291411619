import React from 'react';
import { Container } from 'react-bootstrap';
import './Home.css';
import Header from '../../components/Header/Header';
import Sliders from '../../components/Sliders/Sliders';
import OurFragrances from '../../components/OurFragrances/OurFragrances';
import Features from '../../components/Features/Features';
import Search from '../../components/Search/Search'
import Bestsellers from '../../components/Products/BestSellers';
import StoreInfo from '../../components/StoreInfo/StoreInfo';
import ShopByCollections from '../../components/ShopByCollections/ShopByCollections';
import Subscribe from '../../components/Subscribe/Subscribe';
import CouponCodeSlider from '../../components/CouponCodeSlider/CouponCodeSlider';
import Testimonials from '../../components/Testimonials/Testimonials';
import BlogsHome from '../../components/Blogs/BlogsHome';
import RecentlyAdded from '../../components/Products/RecentlyAdded';
import Showcase from '../../components/Showcase/Showcase';
import Banner from '../../components/Banner/Banner';
import ShopByGender from '../../components/ShopByGender/ShopByGender';
import ShopForHim from '../../components/ShopByGender/ShopForHim';
import ShopForHer from '../../components/ShopByGender/ShopForHer';
import RecentlyViewed from '../../components/Products/RecentlyViewed';
import VideoCarousel from '../../components/VideoCarousel/VideoCarousel';
const components = [
  { component: <Sliders key="Sliders" />, key: 'Sliders' },
  { component: <Banner key="Banner" />, key: 'Banner' },
  { component: <ShopByGender key="ShopByGender" />, key: 'ShopByGender' },
  { component: <Showcase key="Showcase" />, key: 'Showcase' },
  { component: <VideoCarousel key="VideoCarousel" />, key: 'VideoCarousel' },
  { component: <ShopForHim key="ShopForHim" />, key: 'ShopForHim' },
  { component: <RecentlyViewed key="RecentlyViewed" />, key: 'RecentlyViewed' },
  { component: <Features key="Features" />, key: 'Features' },
  { component: <ShopForHer key="ShopForHer" />, key: 'ShopForHer' },
];

function Home() {
  return (
    <div>
      {components.map(({ component, key }) => (
        <div key={key} data-key={key}>
          {component}
        </div>
      ))}
    </div>
  );
}

export default Home;
