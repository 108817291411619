import React, { useContext, useEffect } from 'react';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import './Wishlist.css';
import { WishlistContext } from '../../Utils/WishlistContext';
import ProductCard from '../../components/ProductCard/ProductCard';
import RecentlyAdded from '../../components/Products/RecentlyAdded';
import BestSellers from '../../components/Products/BestSellers';
import Subscribe from '../../components/Subscribe/Subscribe';
import NoWishlistItems from './NoWishlistItems';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineHome } from 'react-icons/ai';
function Wishlist() {
    const baseUrl = 'https://stylespotlightco.in/';
    const navigate = useNavigate();
    const { wishlistItems } = useContext(WishlistContext);



    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToTop();
    }, [navigate]);

    const Breadcrumb = ({ product }) => {
        return (
            <div className='breadcrumb-container'>
                <div className='breadcrumb'>
                    <Link to='/' className='breadcrumb-item text-white me-2'><AiOutlineHome size={20} /></Link>
                    <span className='breadcrumb-separator text-white me-2'>{'/'}</span>
                    <div className='breadcrumb-item text-white me-2'>cart</div>
                </div>
            </div>
        );
    };



    return (
        <div>
            <section className='sectionView'>
                <Container fluid className="px-lg-5 px-md-5 px-sm-5">
                    <Breadcrumb product={wishlistItems} />
                    {wishlistItems.length > 0 ? (
                        <Row className="g-0">
                            {wishlistItems.map((item, index) => (
                                <Col xs={6} md={6} lg={2} key={index}>
                                    <ProductCard item={item} baseUrl={baseUrl} wishlist={item.in_wishlist} cart={item.in_cart} />
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <NoWishlistItems />
                    )}
                    <RecentlyAdded />
                    <BestSellers />
                </Container>
            </section>
        </div>
    );
}

export default Wishlist;
