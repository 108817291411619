import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Policies.css'; // Custom CSS for the font style

const TermsAndConditions = () => {
  return (
    <Container className="terms-of-service sectionView my-5">
      <Row>
        <Col>
          <h1 className="text-center gradient-text">Terms and Conditions</h1>
          <p className="text-center"><em>Last updated: November 11, 2024</em></p>
          
          <section className="mb-5">
            <h2>1. Introduction</h2>
            <p>
              For the purpose of these Terms and Conditions, the terms "we", "us", "our" refer to W3BUILDERS TECHNOLOGY SOLUTIONS LLP, whose registered/operational office is located at No 180, K No 245/33/2/180, Sampigehalli Village, Dr. Shivarama Karanth Nagar, Sampigehalli Police Station, Bangalore North, Bengaluru, Karnataka, 560077. 
              "You", “your”, "user", “visitor” refers to any natural or legal person visiting our website and/or agreeing to purchase from us. <strong>Style Spotlight Co.</strong> operates under W3BUILDERS TECHNOLOGY SOLUTIONS LLP.
            </p>
          </section>

          <section className="mb-5">
            <h2>2. Acceptance of Terms</h2>
            <p>By accessing and using Style Spotlight Co. ("we," "us," "our"), you agree to abide by these Terms of Service. If you do not agree with these terms, please do not use our website.</p>
          </section>

          <section className="mb-5">
            <h2>3. Use of the Website</h2>
            <h3>a. User Conduct</h3>
            <ul>
              <li>You must not use the site for any illegal or unauthorized purpose.</li>
              <li>You must not transmit any harmful code or interfere with the website's functionality.</li>
              <li>You must not violate any applicable laws or regulations while using our site.</li>
            </ul>
            <h3>b. No Cancellation Policy</h3>
            <p>All orders placed through our website are considered final and cannot be cancelled.</p>
            <h3>c. Return and Exchange Policy</h3>
            <p>We do not accept returns or exchanges through our website. Please contact our support team at <a href="mailto:help@stylespotlightco.in">help@stylespotlightco.in</a> or <a href="mailto:info@stylespotlightco.in">info@stylespotlightco.in</a> for assistance.</p>
          </section>

          <section className="mb-5">
            <h2>4. Shipping and Delivery</h2>
            <h3>a. Shipping Methods</h3>
            <p>We offer various shipping methods, including those provided by Professional Couriers.</p>
            <h3>b. Estimated Delivery Times</h3>
            <p>Estimated delivery times are provided for reference and may vary based on location and other factors.</p>
            <h3>c. Delivery Issues</h3>
            <p>If you experience any issues with delivery, please contact our customer support team at <a href="mailto:help@stylespotlightco.in">help@stylespotlightco.in</a> or <a href="mailto:info@stylespotlightco.in">info@stylespotlightco.in</a>.</p>
          </section>

          <section className="mb-5">
            <h2>5. Payment Gateway</h2>
            <p>We do not store card or payment information. All transactions are securely processed through PhonePe Payment Gateway.</p>
          </section>

          <section className="mb-5">
            <h2>6. Content Disclaimer</h2>
            <p>
              The content on the pages of this website is subject to change without notice. We do not provide any warranty or guarantee as 
              to the accuracy, timeliness, performance, completeness, or suitability of the information and materials found on this website for 
              any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors, and we expressly 
              exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
            </p>
          </section>

          <section className="mb-5">
            <h2>7. Use of Information and Materials</h2>
            <p>
              Your use of any information or materials on our website or product pages is entirely at your own risk, for which we shall 
              not be liable. It is your responsibility to ensure that any products, services, or information available through our website 
              and product pages meet your specific requirements.
            </p>
          </section>

          <section className="mb-5">
            <h2>8. Intellectual Property</h2>
            <h3>a. Copyright</h3>
            <p>All content on our website, including text, images, and logos, is protected by copyright. Unauthorized use of our content is prohibited.</p>
            <h3>b. Seeking Permission</h3>
            <p>If you wish to use any of our content, please contact us to request permission.</p>
          </section>

          <section className="mb-5">
            <h2>9. Limitation of Liability</h2>
            <p>We are not liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of our website or any products purchased through it.</p>
          </section>

          <section className="mb-5">
            <h2>10. Governing Law and Dispute Resolution</h2>
            <p>These Terms of Service are governed by the laws of India. Any disputes arising from these terms will be resolved through mediation or arbitration in accordance with local regulations.</p>
          </section>

          <section className="mb-5">
            <h2>11. Contact Us</h2>
            <p>If you have any questions or concerns regarding our Terms of Service, please visit the Contact Us page or reach out to us at <a href="mailto:help@stylespotlightco.in">help@stylespotlightco.in</a> or <a href="mailto:info@stylespotlightco.in">info@stylespotlightco.in</a>.</p>
          </section>

          <footer className="text-center">
            <p>&copy; {new Date().getFullYear()} Style Spotlight Co. operated by W3BUILDERS TECHNOLOGY SOLUTIONS LLP. All rights reserved.</p>
          </footer>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsAndConditions;
