import React, { useState } from 'react';
import { Container, Row, Col, FormControl, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import './Subscribe.css';

function Subscribe() {
    const [emailInput, setEmailInput] = useState('');
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    const handleInputChange = (e) => {
        setEmailInput(e.target.value);
    };

    const handleSubscribe = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://stylespotlightco.in/api/client_api/subscribe/index.php', { email: emailInput });
            setMessage(response.data.message);
            setShowMessage(true);
            setEmailInput(''); // Clear the input field
        } catch (error) {
            setMessage(error.response.data.message);
            setShowMessage(true);
        }

        // Hide the message after 3 seconds
        setTimeout(() => {
            setShowMessage(false);
        }, 3000);
    };

    return (
        <div className='bg-light'>
            <div
                className="py-5 text-start my-5 rounded-0 container"
            >
                <Row>
                    <Col md={6} sm={12} className='d-flex align-items-center justify-content-center justify-content-md-start'>
                        <div className='text-lg-start text-md-start'>
                            <h2 className="my-0 text-uppercase text-black">STAY UP TO DATE ABOUT <br />OUR LATEST OFFERS</h2>
                        </div>
                    </Col>
                    <Col md={6} sm={12} className='d-flex flex-column align-items-center justify-content-center justify-content-md-end'>
                        {message && <p className={`text-center mt-3`}>{message}</p>}

                        <Form onSubmit={handleSubscribe} className='w-100'>
                            <FormControl
                                style={{ border: '1px solid #6c757d', padding: '8px', background: '#f0f0f0', color: '#000' }}
                                aria-label="Enter your email address"
                                placeholder="Enter your email address"
                                value={emailInput}
                                onChange={handleInputChange}
                                className='mb-2 custom-placeholder text-center  rounded-0'
                                required
                                type='email'
                            />
                            <Button type='submit' variant='dark' className='w-100 rounded-0' style={{ borderColor: '#000000', borderWidth: '1px', }}>Subscribe to Newsletter</Button>
                        </Form>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Subscribe;
