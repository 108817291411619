import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Policies.css'; // Custom CSS for the font style

const CookiePolicy = () => {
  return (
    <Container className="cookie-policy sectionView">
      <Row>
        <Col>
          <h1 className="text-center gradient-text">Cookie Policy</h1>
          <p className="text-center"><em>Last updated: 1st September 2024</em></p>
          
          <h2>1. Introduction</h2>
          <p>This Cookie Policy explains how Style Spotlight Co. ("we," "us," "our") uses cookies and similar technologies on our website.</p>
          
          <h2>2. What Are Cookies?</h2>
          <h3>a. Cookies</h3>
          <p>Cookies are small text files stored on your device when you visit our website. They serve various purposes, including enhancing your experience.</p>
          
          <h3>b. Types of Cookies</h3>
          <ul>
            <li><strong>Essential Cookies:</strong> These are necessary for the website to function correctly.</li>
            <li><strong>Analytical Cookies:</strong> These help us analyze website traffic and improve our services.</li>
            <li><strong>Functional Cookies:</strong> These enhance your experience by remembering your preferences.</li>
            <li><strong>Advertising Cookies:</strong> These are used to display relevant ads.</li>
          </ul>
          
          <h2>3. How to Manage Cookies</h2>
          <p>You can manage your cookie preferences through your browser settings. Please note that disabling certain cookies may impact your experience on our website.</p>
          
          <h2>4. Contact Us</h2>
          <p>If you have any questions or concerns regarding our Cookie Policy, please contact us from the contact us page.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default CookiePolicy;
