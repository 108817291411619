import React, { useEffect } from 'react';
import './Aboutus.css';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

// Import images statically
import aboutus from '../../assets/images/about us.png';

function Aboutus() {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToTop();
  }, [navigate]);

  return (
    <Container className='sectionView mt-3'>
      <div className='breadcrumb-container'>
        <div className='breadcrumb'>
          <a href='/' className='breadcrumb-item text-black'>Home</a>
          <div className='breadcrumb-item text-black'>About Us</div>
        </div>
      </div>

      <section className="bg-img1 text-center text-black p-lr-15 p-b-10 p-t-95 container">
        <h2 className="ltext-103 text-black text-center cl4" style={{ margin: '0 15px 30px 15px' }}>About Us</h2>
      </section>

      {/* Our Vision Section */}
      <section className="p-t-5 p-b-120">
        <div className="row pb-5">
          <div className="col-md-7 col-lg-8">
            <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
              <h3 className="mtext-111 cl2 text-black p-b-16">Our Vision</h3>
              <p className="stext-113 text-black cl6 p-b-26">
                To become a leading name in fashion, recognized for our innovative designs and commitment to quality, while inspiring individuals to express their unique style through our apparel.
              </p>
              <p className="stext-113 text-black cl6 p-b-26">
                We started with a passion for timeless classics and have expanded to include contemporary trends that cater to all styles and occasions.
              </p>
            </div>
          </div>
          <div className="col-11 col-md-5 col-lg-4 m-lr-auto">
            <div className="d-flex justify-content-between position-relative">
              <img src={aboutus} alt="Vision Image 1" className='img-instagram-style  w-100' />
            </div>
          </div>
        </div>

        {/* Our Mission Section */}
        <div className="row py-5">
          <div className="order-md-2 col-md-7 col-lg-8 p-b-30">
            <div className="p-t-7 p-l-85 p-l-15-lg p-l-0-md">
              <h3 className="mtext-111 text-black cl4 p-b-16">Our Mission</h3>
              <p className="stext-113 text-black cl6 p-b-26">
                To design and deliver high-quality apparel that celebrates individuality and creativity, empowering our customers to find their perfect fit and style. We aim to create an inclusive fashion community where everyone feels represented and valued.
              </p>
            </div>
          </div>
          <div className="order-md-1 col-11 col-md-5 col-lg-4 m-lr-auto p-b-30">
            <div className="d-flex justify-content-between position-relative">
              <img src={aboutus} alt="Mission Image 2" className='img-instagram-style w-100' style={{ zIndex: '1' }} />
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
}

export default Aboutus;
