import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import './BlogPost.css'
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
const BlogPost = () => {
  const { blogName } = useParams();
  const blog_name = blogName.replace(/-/g, ' ');
  const [selectedBlog, setSelectedBlog] = useState([]);
  const [recentBlogs, setRecentBlogs] = useState([]);
  // const [categories, setCategories] = useState([]);

  const baseURL = 'https://stylespotlightco.in/'

  useEffect(() => {
    getSelectedBlog();
    getrRcentBlogs();
    // getCategories();
  }, [blog_name])

  const getSelectedBlog = async () => {
    try {
      const response = await axios.get(`https://stylespotlightco.in/api/client_api/Blogs/getselectedblog.php?title=${blog_name}`);
      if (response.data.length > 0) {
        setSelectedBlog(response.data[0]);
      } else {
        setSelectedBlog([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getrRcentBlogs = async () => {
    try {
      const response = await axios.get(`https://stylespotlightco.in/api/client_api/Blogs/getrecentblogs.php?title=${blog_name}`);
      if (response.data.length > 0) {
        setRecentBlogs(response.data);
      } else {
        setRecentBlogs([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const getCategories = async () => {
  //   try {
  //     const response = await axios.get(`https://stylespotlightco.in/api/client_api/Blogs/getcollections.php`);
  //     if (response.data.length > 0) {
  //       setCategories(response.data);
  //     } else {
  //       setCategories([]);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options).replace(/ /g, ' ');
  };

  const trimContent = (content, maxLength) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = content;
    const textContent = tempElement.textContent || tempElement.innerText || '';
    return textContent;
  };

  const sanitizeHTML = (html) => {
    return DOMPurify.sanitize(html);
  };

  const generateSlug = (name) => {
    return name.toLowerCase().replace(/ /g, '-')
  };

  return (
    <div id="blog" className="our-blog sectionView">
      <Container fluid className="px-lg-5 px-md-5 px-sm-5">
        <Row>
          <Col lg={8} id="allCategoryBlogs">
            <Row className="pb-4">
              <Col lg={12} className="wow fadeInDown animated" data-wow-duration="1s" data-wow-delay="0.25s" style={{ visibility: 'visible', animationDuration: '1s', animationDelay: '0.25s' }}>
                <div className="section-heading">
                  <h2 className='text-white'>{selectedBlog.title}</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="wow fadeInUp animated" data-wow-duration="1s" data-wow-delay="0.25s" style={{ visibility: 'visible', animationDuration: '1s', animationDelay: '0.25s' }}>
                <div className="left-image h-100">
                  <div>
                    <Image src={baseURL + selectedBlog.image_url} alt={selectedBlog.title} fluid />
                    <div className="info">
                      <div className="inner-content" style={{ position: 'relative !important' }}>
                        <ul className="blogInfo">
                          <li><i className="fa fa-calendar"></i> {formatDate(selectedBlog.doc)}</li>
                          <li><i className="fa fa-users"></i> {selectedBlog.author}</li>
                          <li><i className="fa fa-folder"></i> {selectedBlog.category}</li>
                        </ul>
                        <h4>{selectedBlog.title}</h4>
                        <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(selectedBlog.content) }} />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <div className="fixed-column" style={{ position: 'sticky', top: '10vh' }}>
              <Row>
                {/* <Col lg={12}>
                  <Row className="pb-4">
                    <Col lg={12} className="wow fadeInDown animated" data-wow-duration="1s" data-wow-delay="0.25s" style={{ visibility: 'visible', animationDuration: '1s', animationDelay: '0.25s' }}>
                      <div className="section-heading">
                        <h2 className='text-white'>More <em>Blog</em> Categories</h2>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {categories.map((category, index) => (
                      <Col lg={12} className="blog-cards wow fadeInDown mb-2 animated" key={index} data-wow-duration="1s" data-wow-delay="0.35s" style={{ visibility: 'visible', animationDuration: '1s', animationDelay: '0.35s' }}>
                        <Button variant='white' className='btn btn-light rounded-pill' href={`../blogs/category/${category}`}>{category}</Button>
                      </Col>
                    ))}
                  </Row>
                </Col> */}
                <div className="col-lg-12">
                  <Row className="pb-4">
                    <Col lg={12} className="wow fadeInDown animated" data-wow-duration="1s" data-wow-delay="0.25s" style={{ visibility: 'visible', animationDuration: '1s', animationDelay: '0.25s' }}>
                      <div className="section-heading">
                        <h2 className='text-white'>More <em>Blogs</em> from us</h2>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} className="blog-cards wow fadeInDown mb-2 animated" data-wow-duration="1s" data-wow-delay="0.35s" style={{ visibility: 'visible', animationDuration: '1s', animationDelay: '0.35s' }}>
                      <div className="right-list" style={{ marginLeft: '0 !important' }}>
                        <ul className='ps-0'>
                          {recentBlogs.map((blog, index) => (
                            <a href={`/blog/${generateSlug(blog.title)}`}>
                              <li key={index}>
                                <div className="d-flex justify-content-between pt-2">
                                  <div className="left-content align-self-center" style={{ marginRight: '0 !important' }}>
                                    <span><i className="fa fa-calendar"></i> {formatDate(blog.doc)}</span>
                                    <span><i className="fa fa-folder"></i>{blog.category}</span>
                                    <h4>{blog.title}</h4>
                                  </div>
                                  <div className="right-image">
                                    <Image src={baseURL + blog.image_url} alt={blog.title} fluid />
                                  </div>
                                </div>
                                <p>{blog.description}</p>
                              </li>
                            </a>
                          ))}
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BlogPost;
