import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import SecureLS from "secure-ls";
import './More.css';
import { Link } from 'react-router-dom';

function More({ productBarcode, sku }) {
    const ls = new SecureLS({ encodingType: 'aes' });
    const [products, setProducts] = useState([]);
    const baseUrl = 'https://stylespotlightco.in';
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        if (username == null) {
            if (sessionId !== null) {
                getRelatedProducts(sessionId, 'na');
            }
        } else {
            getRelatedProducts('na', username);
        }
    }, []);

    const getRelatedProducts = async (sessionId, username) => {
        try {
            const response = await axios.get(`${baseUrl}/api/client_api/More/index.php?barcode=${productBarcode}&ipadd=${sessionId}&email=${username}`);
            setProducts(response.data.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const generateSlug = (name) => {
        return name.toLowerCase().replace(/ /g, '-');
    };

    return (
        <div>
            <h5 className='text-black mb-3'>Colors</h5>
            <section className='product-image-grid'>
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    products.map((product, index) => (
                        <Link key={index} to={`/product/${generateSlug(product?.name)}`}>
                            <Image src={`${baseUrl}/${product?.images}`} width={50} alt="Product Image" className={`rounded-2 product-image ${sku == product?.sku ? 'border-3 border-secondary' : 'border-0'}`} />
                        </Link>
                    ))
                )}
            </section>
        </div>
    );
}

export default More;
