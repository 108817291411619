import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import './ShopByGender.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import menplaintshirts from '../../assets/images/gender/menplaintshirts.png'
import mensweatshirts from '../../assets/images/gender/mensweatshirts.png'
import printedtees from '../../assets/images/gender/printedtees.png'
import more from '../../assets/images/gender/more.png'
function ShopForHim() {

    return (
        <section className='pb-2 wow fadeInDown pt-0'>
            <Container>
                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                        <h4 className='title text-black py-2 text-uppercase'>For <span className='gradient-text'>Him</span></h4>
                    </div>
                </div>
                <Row className='g-0'>
                    <Col sm={6} xs={6} lg={3} md={6} className='mb-1'>
                        <Link to="/gender/For Him/Plain%20T-shirts">
                            <Card className='me-1 bg-transparent card-hover h-100 border-0'>
                                <div className="card-img-container  h-100" >
                                    <Card.Img src={menplaintshirts} alt="Gender Image" variant='top' className='h-100' />
                                </div>
                            </Card>
                        </Link>
                    </Col>
                    <Col sm={6} xs={6} lg={3} md={6} className='mb-1'>
                        <Link to="/gender/For Him/Sweatshirts">
                            <Card className='me-1 bg-transparent card-hover h-100 border-0'>
                                <div className="card-img-container  h-100" >
                                    <Card.Img src={mensweatshirts} alt="Gender Image" variant='top' className='h-100' />
                                </div>
                            </Card>
                        </Link>
                    </Col>
                    <Col sm={12} xs={6} lg={3} md={6} className='mb-1'>
                        <Link to="/gender/For Him/Printed%20T-shirts">
                            <Card className='me-1 bg-transparent card-hover h-100 border-0'>
                                <div className="card-img-container  h-100" >
                                    <Card.Img src={printedtees} alt="Gender Image" variant='top' className='h-100' />
                                </div>
                            </Card>
                        </Link>
                    </Col>
                    <Col sm={12} xs={6} lg={3} md={6} className='mb-1'>
                        <Link to="/collection/For Him">
                            <Card className='me-1 bg-transparent card-hover h-100 border-0'>
                                <div className="card-img-container  h-100" >
                                    <Card.Img src={more} alt="Gender Image" variant='top' className='h-100' />
                                </div>
                            </Card>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default ShopForHim;
