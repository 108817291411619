import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import { CartProvider } from '../src/Utils/CartContext'; // Import CartContextProvider
import { WishlistProvider } from '../src/Utils/WishlistContext'; // Import WishlistContextProvider
import Cart from './pages/Cart/Cart';
import Wishlist from './pages/Wishlist/Wishlist';
import Footer from './components/Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProductDetails from './pages/Product/ProductDetails';
import axios from 'axios';
import ProductCategories from './pages/Product/ProductCategories';
import Search from './pages/Search/Search';
import Checkout from './pages/Checkout/Checkout';
import Placed from './pages/Checkout/Placed';
import Product from './pages/Product/Products';
import Collections from './pages/Collections/Collections';
import CollectionView from './pages/Collections/CollectionView';
import StoreLocator from './pages/StoreLocator/StoreLocator';
import Trackorder from './pages/Trackorder/Trackorder';
import Login from './pages/Authentication/Login/Login';
import Signup from './pages/Authentication/Signup/Signup';
import Reset from './pages/Authentication/Reset/Reset';
import Verify from './pages/Authentication/verify/verify';
import Account from './pages/Account/Account';
import Contactus from './pages/Contactus/Contactus';
import Aboutus from './pages/Aboutus/Aboutus';
import BlogPost from './components/Blogs/BlogPost';
import Password from './pages/Authentication/Password/Password';
import { TailSpin } from 'react-loader-spinner';
import TermsAndConditions from './pages/Policies/TermsAndConditions';
import PrivacyPolicy from './pages/Policies/PrivacyPolicy';
import ShippingPolicy from './pages/Policies/ShippingPolicy';
import AccessibilityPolicy from './pages/Policies/AccessibilityPolicy';
import CommunityGuidelines from './pages/Policies/CommunityGuidelines';
import Disclaimer from './pages/Policies/Disclaimer';
import ExchangeReturnPolicy from './pages/Policies/ExchangeReturnPolicy';
import ContactInformation from './pages/Policies/ContactInformation';
import CookiePolicy from './pages/Policies/CookiePolicy';
import CopyrightNotice from './pages/Policies/CopyrightNotice';
import MyOrders from './pages/MyOrders/MyOrders';
import Subscribe from './components/Subscribe/Subscribe';


// app notification import
import { generateToken, messaging } from './notifications/firebase';
import { onMessage } from 'firebase/messaging';
import Gender from './pages/Gender/Gender';
import GenderView from './pages/Gender/GenderView';

const App = () => {
  // const [sessionId, setSessionId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // notification start
    generateToken();
    onMessage(messaging, (payload) => {
      console.log(payload);
    });
    // notification end

    const storedSessionId = sessionStorage.getItem('sessionId');
    if (storedSessionId) {
      // setSessionId(storedSessionId);
      setLoading(false);
    } else {
      getSessionId();
    }

    // Listen for the window onload event
    const handleWindowLoad = () => {
      setLoading(false);
    };

    window.addEventListener('load', handleWindowLoad);

    return () => {
      window.removeEventListener('load', handleWindowLoad);
    };
  }, []);

  const getSessionId = async () => {
    try {
      const response = await axios.get('https://stylespotlightco.in/api/client_api/session_id/index.php');
      const sessionId = response.data.ipadd;
      sessionStorage.setItem('sessionId', sessionId);
    } catch (error) {
      console.error('Error fetching session ID:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <TailSpin color="#000" height={80} width={80} />
      </div>
    );
  }


  return (
    <CartProvider> {/* Wrap entire app with CartContextProvider */}
      <WishlistProvider> {/* Wrap entire app with WishlistContextProvider */}
        <div className="App">
          <Router>
            <Header />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/aboutus' element={<Aboutus />} />
              {/* <Route path='/contactus' element={<Contactus />} /> */}
              <Route path='/cart' element={<Cart />} />
              <Route path='/wishlist' element={<Wishlist />} />
              <Route path='/checkout' element={<Checkout />} />
              <Route path='/placed' element={<Placed />} />
              <Route path='/products' element={<Product />} />
              <Route path='/product/:productName' element={<ProductDetails />} />
              <Route path='/category/:categoryName' element={<ProductCategories />} />
              <Route path='/search' element={<Search />} />
              <Route path='/collections' element={<Collections />} />
              <Route path='/collection/:collectionName' element={<CollectionView />} />
              <Route path='/gender/:genderType' element={<Gender />} />
              <Route path='/gender/:genderType/:collectionName' element={<GenderView />} />
              <Route path='/store-locator' element={<StoreLocator />} />
              <Route path='/track-order' element={<Trackorder />} />
              <Route path='/auth/login' element={<Login />} />
              <Route path='/auth/signup' element={<Signup />} />
              <Route path='/auth/reset' element={<Reset />} />
              <Route path='/password/:user_id/:token' element={<Password />} />
              <Route path="/verify/:user_id/:token" element={<Verify />} />
              <Route path="/account" element={<Account />} />
              <Route path="/myorders" element={<MyOrders />} />
              <Route path="/blog/:blogName" element={<BlogPost />} />
              <Route path="/termsandconditions" element={<TermsAndConditions />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/shippingpolicy" element={<ShippingPolicy />} />
              {/* <Route path="/refundpolicy" element={<RefundPolicy />} /> */}
              <Route path="/accessibilitypolicy" element={<AccessibilityPolicy />} />
              <Route path="/communityguidelines" element={<CommunityGuidelines />} />
              <Route path="/copyrightnotice" element={<CopyrightNotice />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
              <Route path="/exchangereturnpolicy" element={<ExchangeReturnPolicy />} />
              <Route path="/contactinformation" element={<ContactInformation />} />
              <Route path="/cookiepolicy" element={<CookiePolicy />} />
            </Routes>
            <Subscribe />
            <Footer />
          </Router>
        </div>
      </WishlistProvider>
    </CartProvider>
  );
}

export default App;
