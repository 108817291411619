import React, { useState, useContext, useEffect } from 'react';
import { Card, Badge, Button } from 'react-bootstrap';
import { IoStar } from 'react-icons/io5';
import { TbShoppingCartPlus, TbShoppingCartCheck, TbHeartPlus, TbHeartCheck } from "react-icons/tb";
import { AiOutlineHeart, AiOutlineLoading3Quarters, AiOutlineShoppingCart } from "react-icons/ai";
import axios from 'axios';
import { CartContext } from '../../Utils/CartContext';
import { WishlistContext } from '../../Utils/WishlistContext';
import { Link, useNavigate } from 'react-router-dom';
import './ProductCard.css';
import SweetAlert2 from 'react-sweetalert2';
import SecureLS from "secure-ls";

const ProductCard = ({ item, baseUrl, wishlist, cart }) => {
    const ls = new SecureLS({ encodingType: 'aes' });
    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToTop();
    }, [navigate]);

    const { addToCart } = useContext(CartContext);
    const { addToWishlist, removeFromWishlist } = useContext(WishlistContext);

    const [isAddedToCart, setIsAddedToCart] = useState(false);
    const [showLoadingIconCart, setShowLoadingIconCart] = useState(false);
    const [isAddedToWishlist, setIsAddedWishlist] = useState(wishlist === 1);
    const [showLoadingIconWishlist, setShowLoadingIconWishlist] = useState(false);
    const [swalProps, setSwalProps] = useState({});
    const baseURL = 'https://stylespotlightco.in/'

    const handleAddToCart = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');

        setShowLoadingIconCart(true);

        let success = false;
        if (username == null) {
            if (sessionId !== null) {
                success = await addToCart(sessionId, 'na', item);
            }
        } else {
            success = await addToCart('na', username, item);
        }

        setShowLoadingIconCart(false);
        if (success) {
            setIsAddedToCart(true);
            setSwalProps({
                show: true,
                title: 'Successfully added ' + item.prod_name + ' to the cart',
                icon: 'success',
            });
        } else {
            console.error('Failed to add to cart');
        }
    };


    const handleAddToWishlist = async () => {
        setShowLoadingIconWishlist(true);
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item.price[0];
        const success = username
            ? await addToWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
            : await addToWishlist(sessionId, 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });
        if (success) {
            // console.log(`Added ${item.name} to wishlist`);
            setShowLoadingIconWishlist(false);
            setIsAddedWishlist(true);
        } else {
            console.error('Failed to add to wishlist');
            setShowLoadingIconWishlist(false);
        }
    };


    const handleRemoveFromWishlist = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item.price[0];
        const success = username
            ? await removeFromWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
            : await removeFromWishlist(sessionId, 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });
        if (success) {
            console.log(`Removed ${item.name} from wishlist`);
        } else {
            console.error('Failed to remove from wishlist');
        }
    };

    const generateSlug = (name) => {
        return name.toLowerCase().replace(/ /g, '-');
    };

    const Discount = ((item?.price?.[0]?.basePrice - item?.price?.[0]?.discountedPrice) / item?.price?.[0]?.basePrice) * 100;
    const DiscountNearestPercentage = Math.round(Discount);

    return (
        <>
            <Card className='me-1 mb-1 bg-transparent border-0'>
                <Card.Body className="p-0">
                    <div className="w-100 px-2 d-flex align-items-center justify-content-between" style={{ position: 'absolute', top: '10px', zIndex: '1' }}>
                        {item?.price?.[0]?.average_rating > 0 &&
                            <div className='bg-light rounded-3  px-2 py-1 d-flex align-items-center'>
                                <IoStar style={{ color: 'gray' }} />
                                <span className="ml-1 ms-1" style={{ color: 'gray', fontSize: '12px' }}>{String(item?.price?.[0]?.average_rating)}</span>
                            </div>
                        }
                        <div className='bg-transparent rounded-5 p-2 d-flex align-items-center'>
                            {/* {showLoadingIconWishlist ? (
                                <AiOutlineLoading3Quarters size={20} color='#EC1F29' className='rotate' />
                            ) : isAddedToWishlist || wishlist == 1 ? (
                                <TbHeartCheck style={{ color: 'gray', fontSize: '20px' }}  size={20} color='#CC0001' onClick={handleRemoveFromWishlist} />
                            ) : (
                                <TbHeartPlus style={{ color: 'gray', fontSize: '20px' }}  size={20} color='#CC0001' onClick={handleAddToWishlist} />
                            )} */}
                            <span className="badge bg-success text-start abstext-success ms-2 rounded-5">{DiscountNearestPercentage}% off</span>
                        </div>
                    </div>
                    <a href={`/product/${generateSlug(item?.name)}`}
                        className='text-decoration-none text-black'>
                        <div className="product-img-container rounded-3">
                            <Card.Img src={baseURL + item.images[0]} alt="Gender Image" style={{ Height: '40vh !important' }} />
                        </div>
                        <Card.Body className="px-2 py-2 w-100 d-flex justify-content-between bg-white rounded-bottom-pc">
                            <div>
                                <Card.Title className='text-start mb-0 fw-bold product-name'>{item.name}</Card.Title>
                                <div className='d-flex'>
                                    <Card.Text className='text-start text-black product-price me-3'>
                                        Rs. {item.price[0].discountedPrice}
                                    </Card.Text>
                                    <span className="text-muted text-decoration-line-through product-price">Rs {item?.price[0].basePrice}</span>
                                </div>
                            </div>
                        </Card.Body>
                    </a>
                </Card.Body>
            </Card>
            <SweetAlert2 {...swalProps}
                onConfirm={result => {
                    navigate('/cart')
                }}
            />
        </>
    );
};

export default ProductCard;
