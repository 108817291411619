import React, { useState, useEffect, useContext } from 'react';
import { Container, Navbar, Nav, Button, Modal } from 'react-bootstrap';
import './Header.css';
import { IoBagHandleOutline, IoClose, IoLogoFacebook, IoLogoInstagram, IoMenuSharp, IoPersonOutline, IoSearchOutline, IoSparklesSharp, } from "react-icons/io5";
// import logoImageblack from '../../assets/images/brandblack.png';
import logoImagewhite from '../../assets/images/brandwhite.png';
import { CartContext } from '../../Utils/CartContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SecureLS from "secure-ls";
import Marquee from "react-fast-marquee";

function Header() {
    const { cartCount } = useContext(CartContext);
    const [searchInput, setSearchInput] = useState('');
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [headerBackground, setHeaderBackground] = useState(false); // Track header background color
    const [collections, setCollections] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
    };

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (searchInput != '') {
            navigate(`/search/${searchInput}`, {
                state: {
                    searchInput
                }
            });
        }
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        // Close the sidebar first
        handleSidebarClose();

        // After closing the sidebar, show the logout confirmation modal
        setTimeout(() => {
            setShow(true);
        }, 300); // Adjust the delay if necessary
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollTop = window.pageYOffset;

            // Change header background when scrolled down
            if (currentScrollTop > 50) {
                setHeaderBackground(true); // Scrolled down, change background to white
            } else {
                setHeaderBackground(false); // At the top, remove white background
            }

            setLastScrollTop(currentScrollTop);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);


    const [categories, setCategories] = useState([])
    const baseUrl = 'https://stylespotlightco.in/'

    useEffect(() => {
        getCategories();
    }, [])

    const getCategories = async () => {
        try {
            const response = await axios.get('https://stylespotlightco.in/api/client_api/productCategories/index.php');
            setCategories(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    // Initialize SecureLS
    const ls = new SecureLS({ encodingType: 'aes' });

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userName, setUserName] = useState('');
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        // ls.removeAll();
        // Retrieve the stored email and name using SecureLS
        const email = ls.get('userEmail');
        const name = ls.get('userName');

        if (email && name) {
            setIsLoggedIn(true);
            setUserName(name);
        }

        getCollections();

    }, []);

    const SignInLink = () => {
        const location = useLocation();

        const handleSignInClick = () => {
            // Store the current path in sessionStorage
            sessionStorage.setItem('redirectPath', location.pathname);
        };

        return (
            <Link
                to='/auth/login'
                className='text-decoration-none text-white d-flex align-items-center justify-content-center'
                onClick={handleSignInClick} // Call this function on click
            >
                <div className="icon-text-container">
                    <IoPersonOutline size={'22px'} color='#000' className="icon" />
                </div>
            </Link>
        );
    };


    const UserDropdown = () => {
        return (
            <Link
                to='/account'
                className='text-decoration-none text-white d-flex align-items-center justify-content-center'
            >
                <div className="icon-text-container">
                    <IoPersonOutline size={'22px'} color='#000' className="icon" />
                </div>
            </Link>
        );
    };

    const handleMenuClick = () => {
        setSidebarOpen(true);
    };

    const handleSidebarClose = () => {
        setSidebarOpen(false);
    };

    const handleLogout = async () => {
        try {
            // Send the POST request to the logout endpoint
            const response = await axios.post('https://stylespotlightco.in/api/client_api/authentication/logout/index.php');

            // Check if the response indicates success
            if (response.data.success) {
                // Remove user data from SecureLS
                ls.remove('userEmail');
                ls.remove('userName');

                // Update state to reflect logout
                setIsLoggedIn(false);
                setUserName('');

                // Redirect to home page
                window.location.href = 'https://stylespotlightco.in';
            } else {
                // Handle the case where the server response is not successful
                alert('Error logging out, please try again');
            }
        } catch (error) {
            // Handle any errors during the request
            console.error('Error logging out:', error);
            alert('Error logging out: Please try again.');
        }
    };


    const getCollections = async () => {
        try {
            const response = await axios.get('https://stylespotlightco.in/api/client_api/Gender/index.php');
            setCollections(response.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const generateSlug = (name) => {
        return name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    };

    return (
        <div>
            <header className={`custom-header shadow ${headerBackground ? 'header-scrolled' : ''}`}>
                <div className='d-flex py-1' style={{ background: '#a2ff5b' }}>
                    <Marquee pauseOnHover autoFill speed={'70'}>
                        <div className='me-5 d-flex align-items-center'>
                            <p className='text-black text-uppercase mb-0' style={{ fontSize: '14px' }}>3 280GSM Terry Oversized T-Shirts, Men and Women for 1200 only! use coupon code <strong>3T1200</strong></p>
                            <IoSparklesSharp size={'16px'} color='#000' className="icon ms-5" />
                        </div>
                        <div className='me-5 d-flex align-items-center'>
                            <p className='text-black text-uppercase mb-0' style={{ fontSize: '14px' }}>3 180GSM Oversized Printed T-shirts, Men and Women for 999 only! use coupon code <strong>3FOR999</strong></p>
                            <IoSparklesSharp size={'16px'} color='#000' className="icon ms-5" />
                        </div>
                        <div className='me-5 d-flex align-items-center'>
                            <p className='text-black text-uppercase mb-0' style={{ fontSize: '14px' }}>4 classic T-shirts, Men and Women for 999 only! use coupon code <strong>4FOR999</strong></p>
                            <IoSparklesSharp size={'16px'} color='#000' className="icon ms-5" />
                        </div>
                        <div className='me-5 d-flex align-items-center' >
                            <p className='text-black text-uppercase mb-0' style={{ fontSize: '14px' }}>Free shipping across India!</p>
                            <IoSparklesSharp size={'16px'} color='#000' className="icon ms-5" />
                        </div>
                        <div className='me-5 d-flex align-items-center'>
                            <p className='text-black text-uppercase mb-0' style={{ fontSize: '14px' }}>20% off on all products, use coupon code <strong>SAVE20</strong></p>
                            <IoSparklesSharp size={'16px'} color='#000' className="icon ms-5" />
                        </div>
                    </Marquee>
                </div>
                <div className='top-header px-lg-5 px-md-5 px-sm-5'>
                    <Container className="d-flex align-items-center justify-content-between">
                        <div className="logo" style={{ flexBasis: '10%' }}>
                            <Navbar.Brand className='d-flex justify-content-center align-items-center'>
                                <Link to="/">
                                    <img
                                        src={logoImagewhite}
                                        width="auto"
                                        height="70"
                                        className="d-inline-block align-top py-2"
                                        alt="stylespotlightco"
                                    />
                                </Link>
                            </Navbar.Brand>
                        </div>
                        <div className='d-none justify-content-center align-items-center d-lg-flex' style={{ flexBasis: '70%' }}>
                            <Navbar id="basic-navbar-nav">
                                <Nav className="me-auto text-black">
                                    <Nav.Link as={Link} to="" className="text-black fw-bold me-2 bottomNavbarCategories">Home</Nav.Link>
                                    {/* {collections
                                        .filter(item => item?.name !== "All Products") // Exclude "All Products"
                                        .slice(0, 4) // Get the first three items
                                        .map((item, index) => (
                                            <Nav.Link
                                                as={Link}
                                                key={index}
                                                to={`collection/${encodeURIComponent(item?.name)}`} // Encode the name for the URL
                                                className="text-black fw-bold me-2 bottomNavbarCategories"
                                            >
                                                {item?.name}
                                            </Nav.Link>
                                        ))}
                                    {collections.length > 5 && ( // Check if there are more than 4 items total
                                        <Nav.Link
                                            as={Link}
                                            to="collection/All Products" // Link to all products
                                            className="text-black fw-bold me-2 bottomNavbarCategories"
                                        >
                                            More
                                        </Nav.Link>
                                    )} */}
                                    <Nav.Link
                                        as={Link}
                                        to='collection/For Him' // Encode the name for the URL
                                        className="text-black fw-bold me-2 bottomNavbarCategories"
                                    >
                                        For Him
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to='collection/For Her' // Encode the name for the URL
                                        className="text-black fw-bold me-2 bottomNavbarCategories"
                                    >
                                        For Her
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to='collection/Oversized T-shirts' // Encode the name for the URL
                                        className="text-black fw-bold me-2 bottomNavbarCategories"
                                    >
                                        Oversized T-shirts
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to='collection/Printed T-shirts' // Encode the name for the URL
                                        className="text-black fw-bold me-2 bottomNavbarCategories"
                                    >
                                        Printed T-shirts
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to='collection/Plain T-shirts' // Encode the name for the URL
                                        className="text-black fw-bold me-2 bottomNavbarCategories"
                                    >
                                        Plain T-shirts
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to='collection/Polo T-shirts' // Encode the name for the URL
                                        className="text-black fw-bold me-2 bottomNavbarCategories"
                                    >
                                        Polo T-shirts
                                    </Nav.Link>
                                    <Nav.Link as={Link} to="why-us" className="text-black fw-bold me-2 bottomNavbarCategories">Why Us?</Nav.Link>
                                    <Nav.Link as={Link} to="track-order" className="text-black fw-bold me-2 bottomNavbarCategories">Track Order</Nav.Link>
                                </Nav>
                            </Navbar>
                        </div>
                        <div className="d-flex align-items-center justify-content-end" style={{ flexBasis: '20%' }}>
                            <div className='d-flex'>
                                <Link to='search' className='text-decoration-none d-flex align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoSearchOutline size={'22px'} color='#000' className="icon" />
                                    </div>
                                </Link>
                                {isLoggedIn ? <UserDropdown /> : <SignInLink />}
                                {/* <Link to='Wishlist' className='text-decoration-none d-none d-lg-flex align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoHeartOutline size={'22px'} color='#000' className="icon" />
                                        <span className='icon-badge wishlist-badge fw-bold'> {wishlistCount} </span>
                                    </div>
                                </Link> */}
                                <Link to='Cart' className='text-decoration-none d-flex align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoBagHandleOutline size={'22px'} color='#000' className="icon" />
                                        <span className='icon-badge cart-badge fw-bold'> {cartCount} </span>
                                    </div>
                                </Link>
                                <a href='https://www.instagram.com/stylespotlightco/' target='_blank' rel="noreferrer" className='text-decoration-none d-flex align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoLogoInstagram size={'22px'} color='#000' className="icon" />
                                    </div>
                                </a>
                                <a href='https://www.facebook.com/profile.php?id=61569560895937' target='_blank' rel="noreferrer" className='text-decoration-none d-flex align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoLogoFacebook size={'22px'} color='#000' className="icon" />
                                    </div>
                                </a>
                                <div className='text-decoration-none d-flex d-lg-none align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoMenuSharp size={'22px'} color='#000' className="icon"
                                            onClick={() => handleMenuClick()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </header>

            {/* Backdrop */}
            {(isSidebarOpen) && (
                <div className="backdrop show" onClick={handleSidebarClose}></div>
            )}

            {/* Menu Sidebar */}
            <aside className={`wrap-sidebar js-sideorderbar ${isSidebarOpen ? 'show-sidebar' : ''}`}>
                <div className="sidebar p-t-22 p-b-25">
                    <div className="d-flex justify-content-between align-items-center " style={{ position: 'sticky', top: 0, zIndex: 1, }}>
                        <div className="text-dark mobile-menu-toggle-icon f12 fw-bold">
                            <div className="logo" style={{ flexBasis: '10%' }}>
                                <Navbar.Brand className='d-flex justify-content-center align-items-center'>
                                    <Link to="/">
                                        <img
                                            src={logoImagewhite}
                                            width="auto"
                                            height="50"
                                            className="d-inline-block align-top"
                                            alt="stylespotlightco"
                                        />
                                    </Link>
                                </Navbar.Brand>
                            </div>
                        </div>
                        <div className="mobile-menu-toggle-icon js-hide-sideordertrackbar" onClick={handleSidebarClose}>
                            <IoClose size={'22px'} color='#000' className="icon" />
                        </div>
                    </div>
                    <div className="dropdown px-3">
                        <div className="dropdown-options py-2">
                            <Link to="/">
                                Home
                            </Link>
                        </div>
                        {/* {collections
                            .filter(item => item?.name !== "All Products") // Exclude "All Products"
                            .map((item, index) => (
                                <div className="dropdown-options py-2" key={index}>
                                    <Link to={`collection/${encodeURIComponent(item?.name)}`} >
                                        {item?.name}
                                    </Link>
                                </div>
                            ))} */}
                        <div className="dropdown-options py-2">
                            <Link to='collection/For Him' >
                                For Him
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to='collection/For Her' >
                                For Her
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to='collection/Oversized T-shirts' >
                                Oversized T-shirts
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to='collection/Printed T-shirts' >
                                Printed T-shirts
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to='collection/Plain T-shirts' >
                                Plain T-shirts
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to='collection/Polo T-shirts' >
                                Polo T-shirts
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to="contactus">
                                Contact Us
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to="track-order">
                                Track Order
                            </Link>
                        </div>
                    </div>
                </div>
            </aside>

            {/* Logout Confirmation Modal */}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title>Confirm Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to log out?</Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="dark" onClick={handleLogout}>
                        Logout
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Header;
